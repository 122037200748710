import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { ErrorMessage, Field, Form, Formik, FieldArray } from "formik";
import { networkValidationSchema } from "../../../../validations/networkModuleValidation";
import mobioApi from "../../../../services";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";
import MOModalWrapper from "../../../../components/commons/MOModalWrapper";
import { toastMessage } from "../../../../helpers/toastMessage";
import { countryCodeList } from "../../../../data/countryCodeList";
import RegexBuilder from "./RegexBuilder";

const AddNetworkModal = ({
  modalOpen,
  setModalOpen,
  token,
  status = "add",
  networkId,
}) => {
  const formik = useRef();

  const [networkDetails, setNetworkDetails] = useState({});

  const handleSubmit = async (values) => {
    try {
      const api = mobioApi(token);
      if (status === "add") {
        await api.createNetwork(values);
        toastMessage("success", "Network Added");
      } else if (status === "edit") {
        await api.updateNetwork({ ...values, id: networkId });
        toastMessage("success", "Network Updated");
      }
      setModalOpen(false);
    } catch (error) {
      toastMessage("error", error.response?.data?.msg);
    }
  };

  const getNetworkDetails = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getNetworkById(networkId);
      setNetworkDetails(response?.data);
    } catch (error) {
      setNetworkDetails({});
    }
  };

  useEffect(() => {
    if (status === "edit" && networkId) {
      getNetworkDetails();
    } else {
      setNetworkDetails({});
    }
  }, [status, networkId]);

  return (
    <MOModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      className="add-network-modal"
      title={
        <>
          <div>
            <h3 className="font-17 font-500 ml-21 text-black">
              {status === "add" ? "Add Network" : "Edit Network"}
            </h3>
          </div>
        </>
      }
    >
      <div className="pt-8 ml-21 mr-24">
        <Formik
          innerRef={formik}
          initialValues={{
            name: "",
            country: "",
            supportNumber: "",
            countryCode: "",
            documents: networkDetails.networkDocs || [
              {
                name: "",
                imageType: "Single",
                mandatory: false,
                expiryIncluded: false,
                type: "Text",
              },
            ],
            ...networkDetails,
          }}
          validationSchema={networkValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, setValues }) => (
            <Form>
              <div className="mt-26 network-module-fields">
                <h3 className="m-0 mb-8 font-14 font-600 text-black">Name</h3>
                <Field
                  type="text"
                  name="name"
                  placeholder="Enter Network Name"
                  className="w-full"
                />
                <ErrorMessage
                  name="name"
                  component="p"
                  className="text-danger font-10"
                />
              </div>

              <div className="mt-26 network-module-fields">
                <h4 className="m-0 mb-8 font-12 font-600 text-black">
                  Country
                </h4>
                <Field as="select" name="country" className="w-full">
                  {countryCodeList.map((item) => (
                    <option value={item.name}>
                      {item.flag} {item.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="country"
                  component="p"
                  className="text-danger font-10"
                />
              </div>

              <div className="mt-26 network-module-fields">
                <h3 className="m-0 mb-8 font-14 font-600 text-black">City</h3>
                <Field
                  type="text"
                  name="city"
                  placeholder="Enter City"
                  className="w-full"
                />
                <ErrorMessage
                  name="city"
                  component="p"
                  className="text-danger font-10"
                />
              </div>

              <div className="mt-26 network-module-fields">
                <h3 className="m-0 mb-8 font-14 font-600 text-black">
                  Support Phone Number
                </h3>
                <Field
                  type="text"
                  name="supportNumber"
                  placeholder="Enter Support Phone Number"
                  className="w-full"
                />
                <ErrorMessage
                  name="supportNumber"
                  component="p"
                  className="text-danger font-10"
                />
              </div>

              <div className="mt-26 network-module-fields">
                <h3 className="m-0 mb-8 font-14 font-600 text-black">
                  Country Code
                </h3>
                <Field
                  type="text"
                  name="countryCode"
                  placeholder="Enter Country Code"
                  className="w-full"
                />
                <ErrorMessage
                  name="countryCode"
                  component="p"
                  className="text-danger font-10"
                />
              </div>

              {/* Documents */}
              <div className="mt-26">
                <h3 className="m-0 mb-8 font-14 font-600 text-black">
                  Add Network Documents
                </h3>
                <FieldArray name="documents">
                  {({ push, remove }) => (
                    <>
                      {values.documents
                        .sort((a, b) => a.index - b.index)
                        .map((document, index) => (
                          <div key={index} className="document-entry">
                            <span style={{ display: "none" }}>
                              {(values.documents[index].index = index)}
                            </span>
                            <div className="flex align-items-center gap-16 network-module-fields">
                              <div className="flex-1">
                                <h4 className="m-0 mb-8 font-12 font-600 text-black">
                                  Document Name
                                </h4>
                                <Field
                                  type="text"
                                  name={`documents.${index}.name`}
                                  placeholder="Enter Document Name"
                                  className="w-full"
                                />
                                <ErrorMessage
                                  name={`documents.${index}.name`}
                                  component="p"
                                  className="text-danger font-10"
                                />
                              </div>

                              <div className="flex-1">
                                <h4 className="m-0 mb-8 font-12 font-600 text-black">
                                  Document Type
                                </h4>
                                <Field
                                  as="select"
                                  name={`documents.${index}.type`}
                                  className="w-full"
                                >
                                  <option value="Text">Text</option>
                                  <option value="File">File</option>
                                  <option value="Image">Image</option>
                                  <option value="Pattern">Pattern</option>
                                </Field>
                                <ErrorMessage
                                  name={`documents.${index}.type`}
                                  component="p"
                                  className="text-danger font-10"
                                />
                              </div>

                              {values.documents[index].type == "Image" && (
                                <div className="flex-1">
                                  <h4 className="m-0 mb-8 font-12 font-600 text-black">
                                    Image Type
                                  </h4>
                                  <Field
                                    as="select"
                                    name={`documents.${index}.imageType`}
                                    className="w-full"
                                  >
                                    <option value="Single">Single</option>
                                    <option value="Multiple">Multiple</option>
                                    <option value="FrontBack">
                                      Front-Back
                                    </option>
                                    <option value="FrontBackSide">
                                      Front-Back-Side
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    name={`documents.${index}.imageType`}
                                    component="p"
                                    className="text-danger font-10"
                                  />
                                </div>
                              )}
                            </div>

                            {values.documents[index].type == "Pattern" && (
                              <RegexBuilder
                                value={(() => {
                                  if (values.documents[index].pattern) {
                                    try {
                                      return JSON.parse(
                                        values.documents[index].pattern
                                      );
                                    } catch {}
                                  }
                                  return {
                                    value: "",
                                    blocks: [
                                      {
                                        type: "alphabets",
                                        min: 1,
                                        max: 10,
                                        customPattern: "",
                                        error: "",
                                        index: 1,
                                      },
                                    ],
                                  };
                                })()}
                                onChange={(pattern, blocks) => {
                                  setValues({
                                    ...values,
                                    documents: [
                                      ...values.documents.filter(
                                        (_, ind) => ind != index
                                      ),
                                      {
                                        ...values.documents[index],
                                        pattern: JSON.stringify({
                                          value: pattern,
                                          blocks,
                                        }),
                                      },
                                    ],
                                  });
                                }}
                              />
                            )}

                            <div className="mt-16 flex align-items-center gap-16">
                              <div className="flex-1">
                                <label>
                                  <Field
                                    type="checkbox"
                                    name={`documents.${index}.mandatory`}
                                    checked={values.documents[index].mandatory}
                                  />
                                  <span className="ml-8 font-12">
                                    Mandatory
                                  </span>
                                </label>
                              </div>

                              <div className="flex-1">
                                <label>
                                  <Field
                                    type="checkbox"
                                    name={`documents.${index}.expiryIncluded`}
                                    checked={
                                      values.documents[index].expiryIncluded
                                    }
                                  />
                                  <span className="ml-8 font-12">
                                    Expiry Required
                                  </span>
                                </label>
                              </div>

                              {index >= 0 && (
                                <div className="flex-1">
                                  <MOButtonV2
                                    text="Remove"
                                    textColor="#ffffff"
                                    radius={4}
                                    height={32}
                                    padding={"10px 12px"}
                                    backgroundColor="#FF0000"
                                    fontSize={11}
                                    onClick={() => remove(index)}
                                  />
                                </div>
                              )}
                            </div>

                            <hr className="mt-20 mb-20" />
                          </div>
                        ))}

                      <MOButtonV2
                        text="Add Another Document"
                        textColor="#ffffff"
                        radius={4}
                        height={32}
                        padding={"10px 12px"}
                        backgroundColor="#29AEE6"
                        fontSize={11}
                        onClick={() =>
                          push({
                            name: "",
                            imageType: "Single",
                            type: "Text",
                            mandatory: false,
                            expiryIncluded: false,
                          })
                        }
                      />
                    </>
                  )}
                </FieldArray>
              </div>

              {/* Submit Button */}
              <div className="flex align-item-center justify-content-end mt-42">
                <MOButtonV2
                  type="submit"
                  text={status === "add" ? "Add" : "Update"}
                  textColor="#ffffff"
                  radius={4}
                  widthSize={137}
                  height={32}
                  padding={"10px 12px"}
                  backgroundColor="#29AEE6"
                  fontSize={11}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </MOModalWrapper>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(AddNetworkModal);
