import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import * as XLSX from "xlsx";
import ThunksSpreadSheet from "../../../redux/actions/thunks/spread-sheet";
import moment from "moment";
import ThunksBooking from "../../../redux/actions/thunks/bookings";
import ThunksCustomers from "../../../redux/actions/thunks/customer";
import mobioApi from "../../../services";

const useStyles = makeStyles((theme) => ({
  formControl: {},
  formatText: {
    fontSize: 14,
    maxWidth: 260,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      marginLeft: "24%",
    },
  },
};

function getStyles(item, selected, Theme) {
  return {
    fontWeight:
      item.id !== selected
        ? Theme.typography.fontWeightRegular
        : Theme.typography.fontWeightMedium,
  };
}

const ImportModal = ({
  isOpen,
  onPressClose,
  allCustomers,
  ImportSheet,
  getBookings,
  getCustomers,
  token,
}) => {
  const [selected, setSelected] = useState(null);
  const [loader, setLoader] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [editableData, setEditableData] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(true);
  const [isEditable, setIsEditable] = useState(false); // State for toggling edit mode
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [invalidRows, setInvalidRows] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [validationLoader, setValidationLoader] = useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const vehicleCategoryMap = {
    LUXURY: ["LUXURY"],
    EXE_CARRIER: [
      "MPV(Minivan) 7 Seats Business",
      "Toyota Alphard",
      "MPV(Minivan) 7 Seats Business/Toyota Alphard",
      "SUV 7 Seats Comfort",
      "Toyota Highlander",
      "SUV 7 Seats Comfort/Toyota Highlander",
      "EXECUTIVE_PEOPLE_CARRIER",
    ],
    LARGE_CARRIER: [
      "People carrier",
      "MPV(Minivan) 7 Seats Economy",
      "Honda Odyssey",
      "SUV 7 Seats Economy",
      "Honda CR-V",
      "stdmvan8",
      "LARGE_PEOPLE_CARRIER",
      "Premium 7 seater",
      "Business 7 seater",
      "MPV(Minivan) 7 Seats Economy/Honda Odyssey",
      "SUV 7 Seats Economy/Honda CR-V",
    ],
    CARRIER: ["PEOPLE_CARRIER"],
    MINI_BUS: [
      "Minibus 9 Seats Business",
      "Mercedes - Benz V - Class",
      "Minibus 9 Seats Business/Mercedes-Benz V-Class",
      "MINIBUS",
      "Business 8 seater",
      "Business 9 seater",
    ],
    EXECUTIVE: [
      "Executive",
      "Sedan Business EV/Mercedes-Benz EQE/Tesla Model S",
      "Sedan Comfort/Toyota Camry",
      "Sedan Business/Mercedes-Benz E-Class",
      "Sedan Business EV",
      "Mercedes-Benz EQE",
      "Tesla Model S",
      "Sedan Comfort",
      "Toyota Camry",
      "Sedan Business",
      "Mercedes-Benz E-Class",
      "premcar3",
      "vipcar3",
      "EXECUTIVE",
      "Premium 5 seater",
      "Comfort 5 seater",
    ],
    STANDARD: [
      "Standard",
      "Sedan Economy",
      "Toyota Corolla",
      "stdcar4",
      "STANDARD",
      "Economy 5 seater",
    ],
    ELECTRIC: [
      "ELECTRIC",
      "PEOPLE_CARRIER",
      "EXECUTIVE_PEOPLE_CARRIER",
      "LARGE_PEOPLE_CARRIER",
      "ELECTRIC_PEOPLE_CARRIER",
      "ELECTRIC_EXE_PEOPLE_CARRIER",
      "ELECTRIC_LARGE_PEOPLE_CARRIER",
    ],
    ELECTRIC_LUXURY: ["Electric Luxury"],
  };

  const isValidDateFormat = (dateString) => {
    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    return dateRegex.test(dateString);
  };

  const getVehicleCategory = (vehicleType) => {
    if (Object.keys(vehicleCategoryMap).includes(vehicleType)) {
      return vehicleType;
    }

    for (const [category, vehicles] of Object.entries(vehicleCategoryMap)) {
      if (vehicles.includes(vehicleType)) {
        return category;
      }
    }
    return "";
  };

  const isValidVehicleType = (vehicleType) => {
    return (
      Object.keys(vehicleCategoryMap).includes(vehicleType) ||
      Object.values(vehicleCategoryMap).some((vehicles) =>
        vehicles.includes(vehicleType)
      )
    );
  };

  const handleClose = () => {
    onPressClose();
  };

  useEffect(() => {
    const invalidRows = editableData
      .map((row, index) => ({
        index,
        isValid: isValidVehicleType(getVehicleCategory(row[7].trim())),
      }))
      .filter((row) => !row.isValid)
      .map((row) => row.index);

    setInvalidRows(invalidRows);
  }, [editableData]);

  useEffect(() => {
    setTimeout(() => {
      setCheckedRows((prevCheckedRows) =>
        prevCheckedRows.filter((index) => !invalidRows.includes(index))
      );
    }, 0);
  }, [invalidRows, editableData]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelected(value);
  };

  useEffect(() => {
    if (allCustomers?.length <= 0) {
      getCustomers();
    }
  }, []);
  useEffect(() => {
    if (previewOpen && isInitialLoad) {
      setSelectAllChecked(true);
      setCheckedRows(editableData.map((_, index) => index));
      setIsInitialLoad(false);
    }
  }, [previewOpen, editableData, isInitialLoad]);

  useEffect(() => {
    if (!previewOpen) {
      setIsInitialLoad(true);
    }
  }, [previewOpen]);

  const ImportFunction = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    let file = e.target.files[0];
    if (!(file instanceof Blob)) {
      return;
    }

    setValidationErrors({});
    setExcelData([]);
    setEditableData([]);
    setCheckedRows([]);
    setInvalidRows([]);
    setSelectAllChecked(true);
    setIsInitialLoad(true);

    setLoader(true);
    let reader = new FileReader();
    reader.onload = function (e) {
      let data = new Uint8Array(e.target.result);
      let workbook = XLSX.read(data, { type: "array", cellDates: true });
      let worksheetName = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[worksheetName];
      let jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        raw: false,
      });

      const columnCount = jsonData[0].length;

      let addresses = [];
      // Parse and format the dates in the data
      jsonData = jsonData
        .filter((item, index) => index !== 0 && item.length > 0)
        .map((row, rowIndex) => {
          const newRow = new Array(columnCount).fill("");
          newRow[0] = row[0] || "";

          // Assuming the date is in column 1 and time is in column 2
          newRow[1] =
          row[1] && moment(row[1], "DD/MM/YYYY", true).isValid()
          ? moment(row[1], "DD/MM/YYYY").format("DD/MM/YYYY")
          : row[1];
          newRow[2] = row[2] || "";
          newRow[3] = row[3] || "";
          newRow[4] = row[4] || "";
          newRow[5] = row[5] || "";
          newRow[6] = row[6] || "";
          newRow[7] = row[7] ? getVehicleCategory(row[7].trim()) : "";
          newRow[8] = row[8] || "";
          newRow[9] = row[9] || "";
          newRow[10] = row[10] || "";
          newRow[11] = row[11] || "";

          // update performance

          addresses.push({ rowIndex: rowIndex, locations: [] });
          row?.forEach((data, cellIndex) => {
            if (cellIndex === 8 || cellIndex === 9) {
              addresses[rowIndex].locations?.push(data);
            } else {
              validationHandle(data, rowIndex, cellIndex);
            }
          });

          return newRow;
        });

      // call one api for it
      batchLocationValidationApi(addresses);

      setExcelData(jsonData);
      setEditableData(jsonData);
      setPreviewOpen(true);
      setLoader(false);
    };
    reader.readAsArrayBuffer(file);
    e.target.value = null;
  };

  const batchLocationValidationApi = async (value) => {
    try {
      setValidationLoader(true);
      const api = mobioApi(token);
      const result = await api.locationValidation(value);
      const batchLocationValidData = result?.data?.data || [];
      if (batchLocationValidData?.length > 0) {
        batchLocationValidData?.forEach((dataResult) => {
          if (!dataResult.locations[0].length > 0) {
            setValidationErrors((prevErrors) => {
              const updatedErrors = { ...prevErrors };
              updatedErrors[`${dataResult.rowIndex}-${8}`] = "Invalid";
              return updatedErrors;
            });
          } else if (!dataResult.locations[1].length > 0) {
            setValidationErrors((prevErrors) => {
              const updatedErrors = { ...prevErrors };
              updatedErrors[`${dataResult.rowIndex}-${9}`] = "Invalid";
              return updatedErrors;
            });
          }
        });
      }
      setValidationLoader(false);
    } catch (error) {
      return error;
    } finally {
      setValidationLoader(false);
    }
  };

  //   const handleCellChange = (e, rowIndex, cellIndex) => {
  //     const newValue = e.target.value;
  //     setEditableData((prevData) => {
  //       const updatedData = [...prevData];
  //       updatedData[rowIndex][cellIndex] = newValue;
  //       return updatedData;
  //     });
  // };

  let debounceTimeout;

  const debouncedValidationHandle = (newValue, rowIndex, cellIndex) => {
    clearTimeout(debounceTimeout); // Clear the previous timeout

    debounceTimeout = setTimeout(async () => {
      if (cellIndex === 8 || cellIndex === 9) {
        // Address Validation (Google Geocoding API)
        try {
          const api = mobioApi(token);
          const result = await api.locationValidation(newValue);
          const validData = result?.data?.data || [];

          setValidationErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };

            if (validData.length === 0) {
              updatedErrors[`${rowIndex}-${cellIndex}`] = "Invalid";
            } else {
              delete updatedErrors[`${rowIndex}-${cellIndex}`];
            }

            return updatedErrors;
          });
        } catch (error) {
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [`${rowIndex}-${cellIndex}`]: "Error",
          }));
        }
      }
    }, 2000);
  };

  const handleCellChange = async (e, rowIndex, cellIndex) => {
    const newValue = e.target.value.trim();

    setEditableData((prevData) => {
      const updatedData = [...prevData];
      updatedData[rowIndex][cellIndex] = newValue;
      return updatedData;
    });

    // await validationHandle(newValue,rowIndex, cellIndex)

    if (cellIndex === 7) {
      setInvalidRows((prevInvalidRows) => {
        const isInvalid = !isValidVehicleType(newValue.trim());
        if (isInvalid && !prevInvalidRows.includes(rowIndex)) {
          return [...prevInvalidRows, rowIndex];
        } else if (!isInvalid && prevInvalidRows.includes(rowIndex)) {
          return prevInvalidRows.filter((index) => index !== rowIndex);
        }
        return prevInvalidRows;
      });
    }

    // Call validation for other cells (if needed)
    if (cellIndex !== 8 && cellIndex !== 9) {
      await validationHandle(newValue, rowIndex, cellIndex);
    } else {
      debouncedValidationHandle(newValue, rowIndex, cellIndex);
    }
  };

  const validationHandle = async (newValue, rowIndex, cellIndex) => {
    setValidationErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      // Required Field Validation
      if (cellIndex !== 10 && cellIndex !== 3 && newValue === "") {
        updatedErrors[`${rowIndex}-${cellIndex}`] = "Required";
      }

      // Booking Reference Validation (Alphanumeric Only)**
      else if (cellIndex === 0) {
        if (!/^[a-zA-Z0-9]+$/.test(newValue)) {
          updatedErrors[`${rowIndex}-${cellIndex}`] = "Invalid";
        } else if (cellIndex === 0) {
          delete updatedErrors[`${rowIndex}-${cellIndex}`];
        }
      } else if (cellIndex === 1) {
        if (!isValidDateFormat(newValue)) {
          updatedErrors[`${rowIndex}-${cellIndex}`] = "Invalid";
        } else {
          delete updatedErrors[`${rowIndex}-${cellIndex}`];
        }
      }

      // PICKUP TIME Validation (Must be in HH:MM or HH:MM:SS format)
      else if (cellIndex === 2) {
        const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)(:[0-5]\d)?$/; // Supports HH:MM and HH:MM:SS
        if (!timeRegex.test(newValue)) {
          updatedErrors[`${rowIndex}-${cellIndex}`] = "Invalid";
        } else {
          delete updatedErrors[`${rowIndex}-${cellIndex}`];
        }
      }

      // NUMBER OF PASSENGERS Validation (Must be an integer between 1-16)
      else if (cellIndex === 6) {
        if (!/^\d+$/.test(newValue) || newValue > 16 || newValue < 1) {
          updatedErrors[`${rowIndex}-${cellIndex}`] = "Invalid";
        } else {
          delete updatedErrors[`${rowIndex}-${cellIndex}`];
        }
      } else if (cellIndex === 7) {
        // Vehicle Type Validation
        if (!isValidVehicleType(newValue.trim())) {
          updatedErrors[`${rowIndex}-${cellIndex}`] = "Invalid";
        } else {
          delete updatedErrors[`${rowIndex}-${cellIndex}`];
        }
      }
      // Payment Type Validation (Only "cash", "account", "card" allowed)
      else if (
        cellIndex === 11 &&
        !["CASH", "CARD", "ACCOUNT"].includes(newValue.toUpperCase())
      ) {
        updatedErrors[`${rowIndex}-${cellIndex}`] = "Invalid";
      } else {
        delete updatedErrors[`${rowIndex}-${cellIndex}`];
      }

      return updatedErrors;
    });

    // Address Validation (Google Geocoding API)
    if (cellIndex === 8 || cellIndex === 9) {
      try {
        const api = mobioApi(token);
        const result = await api.locationValidation(newValue);
        const validData = result?.data?.data || [];
        setValidationErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          if (!validData.length > 0) {
            updatedErrors[`${rowIndex}-${cellIndex}`] = "Invalid";
          } else {
            delete updatedErrors[`${rowIndex}-${cellIndex}`];
          }
          return updatedErrors;
        });
      } catch (error) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [`${rowIndex}-${cellIndex}`]: "Error",
        }));
      }
    }
  };

  const handleCheckboxChange = (rowIndex) => {
    if (invalidRows.includes(rowIndex)) return;
    setCheckedRows((prevCheckedRows) => {
      const newCheckedRows = [...prevCheckedRows];
      if (newCheckedRows.includes(rowIndex)) {
        const updatedCheckedRows = newCheckedRows.filter(
          (index) => index !== rowIndex
        );
        setSelectAllChecked(false);
        return updatedCheckedRows;
      } else {
        const updatedCheckedRows = [...newCheckedRows, rowIndex];
        if (updatedCheckedRows.length === editableData.length) {
          setSelectAllChecked(true);
        }
        return updatedCheckedRows;
      }
    });
  };

  const handleSelectAllChange = () => {
    if (selectAllChecked) {
      setCheckedRows([]);
    } else {
      setCheckedRows(
        editableData
          .map((_, index) => index)
          .filter((index) => !invalidRows.includes(index))
      );
    }
    setSelectAllChecked(!selectAllChecked);
  };

  const handleSubmit = async () => {
    setLoader(true);
    setIsEditable(false); // Disable edit mode
    setSelectAllChecked(false); // Uncheck "Select All"

    const finalData = {
      data: await transformExcelData(
        editableData.filter((_, index) => checkedRows.includes(index))
      ),
    };

    try {
      await ImportSheet(finalData);
      handleClose();
      await getBookings("planning", "", "", "");
    } catch (error) {
      console.error("Error submitting the data:", error);
    } finally {
      setLoader(false);
      setSelected(null);
      setPreviewOpen(false);
      setExcelData([]);
      setEditableData([]);
      setCheckedRows([]);
    }
  };

  const transformExcelData = async (data) => {
    const epoch = moment().unix();
    const genId = `EX${epoch}`;

    return Promise.all(
      data.map(async (item) => {
        if (!item[1] || !item[2] || !item[6] || !item[7] || !item[8]) {
          return;
        }

        // Combine date and time columns (item[1] for date, item[2] for time)
        const combinedDateTime = `${item[1]} ${item[2]}`;

        const excelDate = convertToExcelDate(combinedDateTime);
        console.log("Combined DateTime:", excelDate); // Log combined datetime

        return {
          bookingReference: item[0],
          dateTime: excelDate, // Use the converted Excel serial date number
          noOfPeople: item[6].toString(),
          carType: item[7],
          notes: item[10],
          paymentType: item[11],
          pickup: item[8].trim(),
          destination: item[9].trim(),
          guestPhoneNumber: item[5],
          guestName: item[4],
          flightNumber: item[3],
          refId: genId,
          clientId: selected,
        };
      })
    );
  };

  function generateExcel() {
    const data = [
      [
        "BOOKING REFERENCE",
        "PICKUP DATE",
        "PICKUP TIME",
        "FLIGHT NUMBER",
        "PASSENGER NAME",
        "CUSTOMER CONTACT NUMBER",
        "NUMBER OF PASSENGERS",
        "VEHICLE TYPE",
        "PICKUP ADDRESS",
        "DROP OFF ADDRESS",
        "PASSENGER COMMENTS",
        "PAYMENT TYPE",
      ],
      [
        "xxxxxxxxxxx",
        "11/23/2024",
        "23:24:00",
        "XXXXXX",
        "Passenger Name",
        "XXXXXXXXXX",
        "no of passengers (1,2,3 etc)",
        "vehicle Type (e.g STANDARD)",
        "Address 1",
        "Address 2",
        "Passenger comment if have",
        "payment type (ie. CASH,CARD,ACCOUNT)",
      ],
    ];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "booking_format_example.xlsx");
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      disableBackdropClick={loader} // Disable backdrop click while loading
      disableEscapeKeyDown={loader} // Disable escape key while loading
    >
      {loader && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Select Customer For Which You Want To Import The Bookings
      </DialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel id="demo-mutiple-chip-label">Customers</InputLabel>
          <Select
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            displayEmpty={true}
            value={selected}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" />}
            MenuProps={MenuProps}
            disabled={loader} // Disable select while loading
          >
            {allCustomers.map((item) => (
              <MenuItem
                key={item.id}
                value={item.id}
                style={getStyles(item, selected, theme)}
              >
                {item.companyName || `${item.firstName} ${item.lastName}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <input
          type="file"
          accept=".xlsx,.xls"
          onChange={ImportFunction}
          id="fileUpload"
          style={{ display: "none" }}
          disabled={loader} // Disable file input while loading
        />
        <Typography className={classes.formatText}>
          Just click <strong>'Download Format'</strong> for the right format
          needed to import your bookings.
        </Typography>
        <Button
          style={{
            borderRadius: 8,
            backgroundColor: "#2AABD5",
            color: "#FFFFFF",
            height: 40,
            cursor: "pointer",
          }}
          onClick={generateExcel}
          variant="contained"
          disabled={loader} // Disable button while loading
        >
          Download Format
        </Button>

        <Button
          style={{
            borderRadius: 8,
            backgroundColor: "#2AABD5",
            color: "#FFFFFF",
            height: 40,
            cursor: "pointer",
          }}
          disabled={!selected || loader} // Disable button while loading
          onClick={() => document.getElementById("fileUpload").click()}
          variant="contained"
        >
          Select File
        </Button>
      </DialogActions>

      {/* Preview Dialog */}
      <Dialog
        onClose={() => setPreviewOpen(false)}
        open={previewOpen}
        maxWidth="xl" // Increased modal size
        fullWidth
        disableBackdropClick={loader} // Disable backdrop click while loading
        disableEscapeKeyDown={loader} // Disable escape key while loading
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Preview Excel Data</Typography>
            <Button
              style={{ marginLeft: "auto" }}
              onClick={() => setIsEditable(!isEditable)}
              variant="contained"
              color={isEditable ? "secondary" : "primary"}
              disabled={loader} // Disable button while loading
            >
              {isEditable ? "Disable Edit" : "Enable Edit"}
            </Button>
          </div>
        </DialogTitle>

        <DialogContent>
          {/* <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={selectAllChecked}
                    onChange={handleSelectAllChange}
                    disabled={loader} // Disable checkbox while loading
                  />
                </TableCell>
                {[
                  "BOOKING REFERENCE",
                  "PICKUP DATE ",
                  "PICKUP TIME",
                  "FLIGHT NUMBER",
                  "PASSENGER NAME",
                  "CUSTOMER CONTACT NUMBER",
                  "NUMBER OF PASSENGERS",
                  "VEHICLE TYPE",
                  "PICKUP ADDRESS",
                  "DROP OFF ADDRESS",
                  "PASSENGER COMMENTS",
                  "PAYMENT TYPE",
                ].map((header, index) => (
                  <TableCell key={index}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {editableData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell>
                    <Checkbox
                      checked={checkedRows.includes(rowIndex)}
                      onChange={() => handleCheckboxChange(rowIndex)}
                      disabled={loader || invalidRows.includes(rowIndex)} 

                    />
                  </TableCell>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex}> 
                      {
                        cellIndex === 7 ? ( 
                        <Select
                          value={row[cellIndex] || ""}
                          onChange={(e) => handleCellChange(e, rowIndex, cellIndex)}
                          fullWidth
                          disabled={!isEditable || loader} 
                          style={{
                            borderBottom: invalidRows.includes(rowIndex) ? '2px solid red' : 'none',
                          }}
                        >
                          {Object.keys(vehicleCategoryMap).map((category) => (
                            <MenuItem key={category} value={category}>
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                          <TextField
                            value={cell}
                            onChange={(e) =>
                              handleCellChange(e, rowIndex, cellIndex)
                            }
                            fullWidth
                            disabled={!isEditable || loader} 
                        />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table> */}

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={selectAllChecked}
                    onChange={handleSelectAllChange}
                    disabled={loader}
                  />
                </TableCell>
                {[
                  "BOOKING REFERENCE",
                  "PICKUP DATE",
                  "PICKUP TIME",
                  "FLIGHT NUMBER",
                  "PASSENGER NAME",
                  "CUSTOMER CONTACT NUMBER",
                  "NUMBER OF PASSENGERS",
                  "VEHICLE TYPE",
                  "PICKUP ADDRESS",
                  "DROP OFF ADDRESS",
                  "PASSENGER COMMENTS",
                  "PAYMENT TYPE",
                ].map((header, index) => (
                  <TableCell key={index}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {editableData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell>
                    <Checkbox
                      checked={checkedRows.includes(rowIndex)}
                      onChange={() => handleCheckboxChange(rowIndex)}
                      disabled={loader || invalidRows.includes(rowIndex)}
                    />
                  </TableCell>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex}>
                      {cellIndex === 7 ? (
                        <FormControl fullWidth>
                          <Select
                            value={row[cellIndex] || ""}
                            onChange={(e) =>
                              handleCellChange(e, rowIndex, cellIndex)
                            }
                            fullWidth
                            disabled={!isEditable || loader}
                          >
                            {Object.keys(vehicleCategoryMap).map((category) => (
                              <MenuItem key={category} value={category}>
                                {category}
                              </MenuItem>
                            ))}
                          </Select>
                          {validationErrors[`${rowIndex}-${cellIndex}`] && (
                            <FormHelperText style={{ color: "red" }}>
                              {validationErrors[`${rowIndex}-${cellIndex}`]}
                            </FormHelperText>
                          )}
                        </FormControl>
                      ) : (
                        <FormControl fullWidth>
                          <TextField
                            value={cell}
                            onChange={(e) =>
                              handleCellChange(e, rowIndex, cellIndex)
                            }
                            fullWidth
                            disabled={!isEditable || loader}
                          />
                          {validationErrors[`${rowIndex}-${cellIndex}`] && (
                            <FormHelperText style={{ color: "red" }}>
                              {validationErrors[`${rowIndex}-${cellIndex}`]}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setPreviewOpen(false)}
            variant="contained"
            color="secondary"
            disabled={loader}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={
              loader ||
              checkedRows.length === 0 ||
              Object.keys(validationErrors).length > 0 ||
              validationLoader
            }
          >
            Submit
          </Button>
        </DialogActions>
        {Object.keys(validationErrors).length > 0 && (
          <Typography
            style={{
              textAlign: "right",
              color: "red",
              paddingBottom: "12px",
              paddingRight: "8px",
            }}
          >
            Please fix the errors before submitting.
          </Typography>
        )}
      </Dialog>
    </Dialog>
  );
};
function convertToExcelDate(dateStr) {
  // Parse the custom date format "DD/MM/YYYY HH:mm"
  const parts = dateStr.split(/[\s/:]/); // Split by space, colon, or slash
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Months are 0-based
  const year = parseInt(parts[2], 10);
  const hours = parseInt(parts[3], 10);
  const minutes = parseInt(parts[4], 10);

  // Create the Date object
  const localDate = new Date(year, month, day, hours, minutes);

  console.log("Original Date:", localDate);

  // Create a UTC Date object
  const utcDate = new Date(
    Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localDate.getHours(),
      localDate.getMinutes(),
      localDate.getSeconds()
    )
  );

  console.log("UTC Date:", utcDate);

  // Excel's base date is January 1, 1900
  const excelBaseDate = new Date(Date.UTC(1900, 0, 1));

  // Calculate the number of days between the base date and the UTC date
  const days = Math.floor((utcDate - excelBaseDate) / (1000 * 60 * 60 * 24));

  // Calculate the fraction of the day
  const fractionOfDay =
    utcDate.getUTCHours() / 24 +
    utcDate.getUTCMinutes() / 1440 +
    utcDate.getUTCSeconds() / 86400;

  // Adding 2 accounts for Excel's incorrect leap year handling
  const excelDate = days + 2 + fractionOfDay;

  console.log("Final Excel Serial Date:", excelDate);
  return excelDate;
}

const mapState = (state) => ({
  allCustomers: state?.customers?.response?.data || [],
  token: state.userInfo.token,
});

const mapDispatch = {
  ImportSheet: ThunksSpreadSheet.ImportSheet,
  getBookings: ThunksBooking.BookingsListing,
  getCustomers: ThunksCustomers.Customers,
};

export default connect(mapState, mapDispatch)(ImportModal);
