import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Dialog,
  DialogTitle,
  makeStyles,
  Button,
  Drawer,
  Typography,
  Slider,
  Box,
  Divider,
  InputAdornment,
} from "@material-ui/core";
import Bookings from "../../../../redux/actions/local/bookings";
import MuiPhoneNumber from "material-ui-phone-number";
import ThunksBookings from "../../../../redux/actions/thunks/bookings";
import ExclusiveSelect from "../../../../components/commons/ExclusiveSelect";
import CarExclusiveSelect from "../../../../components/commons/CarExclusiveSelect";
import DatePicker from "../../../../components/commons/DatePicker";
import LocationField from "../../../../components/commons/LocationField";
import Input from "../../../../components/commons/Input";
import MenuItem from "@material-ui/core/MenuItem";

import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import ThunksCustomers from "../../../../redux/actions/thunks/customer";
import Local from "../../../../redux/actions/local";
import ErrorSnack from "../../../../components/commons/ErrorSnack";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ThunksPassengers from "../../../../redux/actions/thunks/passengers";
import { useDebounce } from "usehooks-ts";
import { update } from "lodash";
import ThunksBooking from "../../../../redux/actions/thunks/bookings";
import MOButton from "../../../../components/commons/MOButton";
import { Man2Outlined } from "@mui/icons-material";
import {
  ChatBubbleOutlineOutlined,
  SupervisedUserCircleOutlined,
} from "@material-ui/icons";
import {
  FlightPlaneIcon,
  LuggageIcon,
  PersonIcon,
  SignalPassengerIcon,
} from "../../../../assets/icons";
import {
  validateFlightNumber,
  validateText,
} from "../../../../validations/customValidation";
import { finalPriceCalculation } from "../../../../helpers/FinalPriceCalculation";

const useStyles = makeStyles((theme) => ({
  disabled: {
    backgroundColor: "red",
  },
  section: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff",
  },
  dialogPaper: {
    margin: theme.spacing(2),
    width: 596,
  },
  heading: {
    fontSize: 20,
    fontWeight: 500,
    color: "#000000",
  },
  headingDetail: {
    fontSize: 12,
    // fontWeight:500,
    color: "#787486",
  },
  button: {
    borderRadius: 6,
    backgroundColor: "#2AABD5",
    color: "#FFFFFF",
    height: 40,
    marginLeft: 10,

    "&:disabled": {
      backgroundColor: "#8bd2e8",
      borderRadius: 6,
      color: "#FFFFFF",
      height: 40,
      marginLeft: 10,
    },
  },
  textfield_upper: {
    marginTop: 14,
    marginBotton: 10,
    height: 40,
  },
  textfield_lower: {
    height: 40,
  },
  textfield_side: {
    height: 40,
  },
  ExclusiveSelect: {
    marginRight: 26,
  },
  MultiSelect: {
    marginTop: 20,
  },
  select: {
    marginTop: 20,
    width: 310,
  },
  autoComplete: {
    backgroundColor: "red",
  },
}));

function SimpleDialog({
  bookingState,
  open,
  onClose,
  updateField,
  dispatchers,
  loading,
  getDispatchers,
  createBooking,
  createCustomerBooking,
  role,
  reset,
  error,
  success,
  createBookingError,
  createBookingSuccess,
  getPassengers,
  passengers,
  createPassenger,
  createPassengerError,
  createPassengerSuccess,
  getCustomers,
  getTotalAndAssignedRides,
  resetAll,
  setSearchText
}) {
  const classes = useStyles();
  const [guestName, setGuestName] = useState("");
  const [inputGuestName, setInputGuestName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [passengerCreate, setPassengerCreate] = useState(false);
  const debouncedValue = useDebounce(guestName, 500);
  const [guestNameEmpty, setGuestNameEmpty] = useState(false);
  const [createError, setCreateError] = useState("");
  const [noteOpen, setNoteOpen] = useState(false);
  const [numberOfGuest, setNumberOfGuest] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [errorTrigger, setErrorTrigger] = useState(false);
  const [isDriverPriceEditable,setIsDriverPriceEditable]=useState(false)

  useEffect(() => {
    if (guestName) {
      updateField("guestName", guestName);
      const passenger = passengers.find(
        (obj) => obj.passengerName === guestName
      );
      updateField("guestPhoneNumber", passenger.phone);
      setPhoneNumber(passenger.phone);
    } else {
      setPhoneNumber("");
    }
  }, [guestName]);
  useEffect(() => {
    if (guestName !== inputGuestName) {
      updateField("guestName", inputGuestName);
      setPhoneNumber("");
    }
  }, [guestName, inputGuestName]);

  const handleChange = (event) => {
    updateField("clientId", event.target.value);
  };
  useEffect(() => {
    if (open) {
      if (role !== "Customer" && role !== "CustomerUser") {
        getCustomers();
        getDispatchers();
        updateField("dateTime", bookingState?.dateTime || new Date());
      }
    }
    setErrorTrigger(false);
    // alert(JSON.stringify(dispatchers));
  }, [open]);
  const savePassenger = async () => {
    if (guestName !== inputGuestName) {
      updateField("guestName", inputGuestName);
      let resp = await createPassenger({
        passengerName: inputGuestName,
        phone: bookingState.guestPhoneNumber,
      });
      if (resp == true) {
        setPassengerCreate(true);
      }
      if (resp == false) {
        setGuestNameEmpty(true);
      } else {
        setCreateError(resp);
      }
    } else {
      updateField("guestName", guestName);
      let resp = await createPassenger({
        passengerName: guestName,
        phone: bookingState.guestPhoneNumber,
      });
      if (resp == true) {
        setPassengerCreate(true);
      }
      if (resp == false) {
        setGuestNameEmpty(true);
      } else {
        setCreateError(resp);
      }
    }
  };

  useEffect(() => {
    async function getPass() {
      await getPassengers();
    }
    getPass();
    // if (bookingState && bookingState?.dateTime == "") {
    //   updateField("dateTime", new Date());
    // }
    updateField("dateTime", new Date());
  }, []);

  useEffect(() => {
    const myClient = dispatchers.find(
      (item) => item.id === bookingState.clientId
    );
    if (
      myClient &&
      myClient.commissions &&
      myClient.commissions.length > 0 &&
      Number(bookingState.fare) > 0
    ) {
      const calculatedFinalPrice = finalPriceCalculation(
        bookingState,
        role,
        myClient.commissions
      );
      updateField("finalPrice", calculatedFinalPrice);
      if(calculatedFinalPrice>0){
        setIsDriverPriceEditable(false)
      }else{
        setIsDriverPriceEditable(true)
      }
    } else {
      updateField("finalPrice", 0);
      setIsDriverPriceEditable(true)
    }
  }, [bookingState.clientId, bookingState.carType, bookingState.fare]);

  return (
    <>
      <Drawer
        // disableBackdropClick
        // onClose={onClose}
        // aria-labelledby="simple-dialog-title"
        // open={open}
        anchor={"right"}
        open={open}
        onClose={onClose}
      >
        <div className={classes.dialogPaper}>
          <Grid
            container
            direction="column"
            // justifyContent="center"
            // alignItems="center"
          >
            <Typography className={classes.heading}>Add Booking</Typography>
            <Typography className={classes.headingDetail}>
              Provide the information to finalise your car reservation
            </Typography>
          </Grid>

          <Grid style={{ marginTop: 16 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              // alignItems="center"
              className={classes.ExclusiveSelect}
            >
              <Box>
                <DatePicker
                  date={
                    bookingState.dateTime !== ""
                      ? bookingState.dateTime
                      : new Date()
                  }
                  // date={new Date()}
                  onChange={(date) => updateField("dateTime", date)}
                />
                {errorTrigger && !bookingState.dateTime && (
                  <p
                    className="error-text-danger error-text-style"
                    style={{ margin: "0" }}
                  >
                    Please select the date and time.
                  </p>
                )}
              </Box>
              <MOButton title="Now" icon={false} />
            </Grid>
            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 18 }}
            >
              <PersonIcon />
              <Typography
                style={{
                  fontSize: 17,
                  fontWeight: 400,
                  marginLeft: 3,
                  marginRight: 6,
                }}
              >
                {numberOfGuest}
              </Typography>
              <Slider
                size="small"
                defaultValue={1}
                step={1}
                min={1}
                max={16}
                aria-label="Small"
                valueLabelDisplay="auto"
                onChange={(e, newValue) => {
                  setNumberOfGuest(newValue);
                  updateField("noOfPeople", newValue);
                }}
              />

              <ChatBubbleOutlineOutlined
                style={{ marginRight: 62, marginLeft: 6, cursor: "pointer" }}
                onClick={() => {
                  setNoteOpen(!noteOpen);
                }}
              />
            </Box>
            {errorTrigger && !bookingState.noOfPeople && (
              <p
                className="error-text-danger error-text-style"
                style={{ margin: "0", marginBottom: "4px" }}
              >
                Please Select the number of Peoples
              </p>
            )}

            <Box style={{ display: "flex" }}>
              <ExclusiveSelect
                value={bookingState?.paymentType}
                onClick={(value) => updateField("paymentType", value)}
                refresh={refresh}
              />
              <Box style={{ width: "100%", marginLeft: "12px" }}>
                {noteOpen && (
                  <Input
                    fullWidth
                    placeholder="Note"
                    size="small"
                    multiline
                    value={bookingState.note}
                    className={classes.textfield_side}
                    onChange={(e) => updateField("note", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ChatBubbleOutlineOutlined
                            style={{ color: "#AAAAAA" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Box>
            </Box>

            <Divider light style={{ marginTop: 22, marginBottom: 12 }} />

            {role !== "Customer" && role !== "CustomerUser" && (
              <>
              
              <Input
                value={bookingState.clientId}
                onChange={handleChange}
                select
                variant={"outlined"}
                label="Requested By"
                size="small"
                fullWidth
                className={classes.textfield_upper}
              >
                <MenuItem key={"none"} value={""}>
                  {"None"}
                </MenuItem>
                {!loading &&
                  dispatchers?.map((item, index) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.companyName}
                    </MenuItem>
                  ))}
              </Input>

              {errorTrigger && !bookingState.clientId && (
                    <p
                      className="error-text-danger error-text-style"
                      style={{ 

                        marginTop:"4px"
                       }}
                    >
                      Requested By is required
                    </p>
                  )}
              </>

            )}

            {/* <Input
              label="Name Caller"
              size="small"
              fullWidth
              value={bookingState.nameCaller}
              className={classes.textfield_upper}
              onChange={(e) => updateField("nameCaller", e.target.value)}
            /> */}

            {/* Passenger Name */}
            <Grid
              container
              spacing={2}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              {/* <Grid item xs={12}>
                <Input
                  fullWidth
                  label="Note"
                  size="small"
                  multiline
                  value={bookingState.note}
                  className={classes.textfield_side}
                  onChange={(e) => updateField("note", e.target.value)}
                />
              </Grid> */}
              {role == "Customer" || role == "CustomerUser" ? (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Autocomplete
                      freeSolo
                      id="free-solo-2-demo"
                      // options={passengers.map(
                      //   (passenger) => passenger?.passengerName
                      // )}
                      options={passengers.map(
                        (passenger) =>
                          !!passenger?.passengerName && passenger.passengerName
                      )}
                      // value={guestName}
                      value={bookingState.guestName}
                      onChange={(event, newValue) => {
                        setGuestName(newValue);
                      }}
                      onInputChange={(event, newInputValue) => {
                        setInputGuestName(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Passenger Name"
                          // placeholder="Passenger Name"
                          InputProps={{
                            ...params.InputProps,
                            // type: 'search',
                            // clearIcon: { cursor: 'pointer !important' }
                            startAdornment: (
                              <InputAdornment position="start">
                                <SignalPassengerIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      sx={{ width: "100%", borderRadius: 8 }}
                    />

                    {/* <Button style={{ background: "#2aabd5", marginLeft: "10px" }} onClick={savePassenger}>
                    <BookmarkAddOutlinedIcon sx={{ color: "white" }} />
                  </Button> */}
                  </Grid>
                  {errorTrigger && !bookingState.guestName && (
                    <p
                      className="error-text-danger error-text-style"
                      style={{ margin: "-4px 0 8px 12px" }}
                    >
                      Passenger Name is required.
                    </p>
                  )}
                </>
              ) : (
                <>
                  <Grid item xs={6}>
                    <Input
                      fullWidth
                      label="Guest Name "
                      size="small"
                      value={bookingState.guestName}
                      className={classes.textfield_side}
                      onChange={(e) => updateField("guestName", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      fullWidth
                      label="Booking Id "
                      size="small"
                      value={bookingState.bookingReference}
                      className={classes.textfield_side}
                      onChange={(e) =>
                        updateField("bookingReference", e.target.value)
                      }
                    />
                  </Grid>
                  {/* {(errorTrigger && !bookingState.guestName ) &&
                    <p className="error-text-danger error-text-style" style={{margin:"-4px 0 8px 12px"}}>
                        Guest Name is required.
                    </p>
                  } */}
                  {errorTrigger && validateText(bookingState.guestName) && (
                    <p
                      className="error-text-danger error-text-style"
                      style={{ margin: "-4px 0 8px 12px" }}
                    >
                      {validateText(bookingState.guestName, "Guest Name")}
                    </p>
                  )}
                </>
              )}
              <Grid item xs={12}>
                <Grid item xs={6}>
                  {/* {
                    phoneNumber !== "" ? 
                    <Input
                      fullWidth
                      label="Passenger Phone Number"
                      value={phoneNumber}
                      size="small"
                      className={classes.textfield_lower}
                      onChange={(e) =>
                        updateField("guestPhoneNumber", e.target.value)
                      }
                      
                    /> 
                    : <Input
                      fullWidth
                      label="Passenger Phone Number"
                      value={bookingState.guestPhoneNumber}
                      size="small"
                      className={classes.textfield_lower}
                      onChange={(e) =>
                        updateField("guestPhoneNumber", e.target.value)
                      }
                    />
                  } */}
                  {phoneNumber !== "" ? (
                    <>
                      <MuiPhoneNumber
                        fullWidth
                        variant="outlined"
                        label="Passenger Phone Number"
                        defaultCountry={"nl"}
                        className={classes.textfield_lower}
                        size="small"
                        value={phoneNumber}
                        onChange={(phoneNumber) => {
                          updateField("guestPhoneNumber", phoneNumber);
                        }}
                      />
                      {errorTrigger &&
                        bookingState.guestPhoneNumber.length < 3 && (
                          <span className="error-text-danger error-text-style">
                            Phone number is required.
                          </span>
                        )}
                    </>
                  ) : (
                    <>
                      <MuiPhoneNumber
                        fullWidth
                        variant="outlined"
                        label="Passenger Phone Number"
                        defaultCountry={"nl"}
                        className={classes.textfield_lower}
                        size="small"
                        value={bookingState.guestPhoneNumber}
                        onChange={(phoneNumber) => {
                          updateField("guestPhoneNumber", phoneNumber);
                        }}
                      />

                      {errorTrigger &&
                        bookingState.guestPhoneNumber.length < 3 && (
                          <span className="error-text-danger error-text-style">
                            Phone number is required.
                          </span>
                        )}
                    </>
                  )}
                </Grid>
                <Divider light style={{ marginTop: 18 }} />
              </Grid>

              <Grid item xs={6}>
                <Input
                  fullWidth
                  label="Flight Number "
                  // placeholder="Flight Number "
                  size="small"
                  value={bookingState.flightNumber}
                  className={classes.textfield_side}
                  onChange={(e) => updateField("flightNumber", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FlightPlaneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {errorTrigger &&
                  validateFlightNumber(bookingState.flightNumber) && (
                    <p
                      className="error-text-danger error-text-style"
                      style={{ margin: "-4px 0 8px 12px" }}
                    >
                      {validateFlightNumber(bookingState.flightNumber)}
                    </p>
                  )}
              </Grid>

              {/* <Grid item xs={6}>
                <Input
                  fullWidth
                  label="Number Of Luggage "
                  size="small"
                  // value={bookingState.flightNumber}
                  className={classes.textfield_side}
                  // onChange={(e) => updateField("flightNumber", e.target.value)}
                />
              </Grid> */}
              <Grid
                item
                xs={role !== "Customer" && role !== "CustomerUser" ? 12 : 6}
              >
                <Input
                  select
                  value={bookingState?.noOfLuggage || null}
                  fullWidth
                  size="small"
                  variant="outlined"
                  className={classes.textfield_lower}
                  onChange={(e) => updateField("noOfLuggage", e.target.value)}
                  // placeholder="Number Of Luggage"
                  label="Number Of Luggage"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LuggageIcon />
                      </InputAdornment>
                    ),
                  }}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                    (item, index) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    )
                  )}
                </Input>
              </Grid>

              {role !== "Customer" && role !== "CustomerUser" && (
                <Grid item xs={6}>
                  <Input
                    fullWidth
                    label="Customer Price"
                    type="number"
                    size="small"
                    value={bookingState.fare}
                    className={classes.textfield_side}
                    onChange={(e) => updateField("fare", e.target.value)}
                  />
                </Grid>
              )}
              {role !== "Customer" && role !== "CustomerUser" && (
                <Grid item xs={6}>
                  <Input
                    fullWidth
                    label="Driver Price"
                    type="number"
                    size="small"
                    value={bookingState.finalPrice}
                    className={classes.textfield_side}
                    // onChange={null}
                    onChange={(e) => updateField("finalPrice", e.target.value)}
                    disabled={!isDriverPriceEditable}
                  />
                </Grid>
              )}
              {/* <Grid
                item
                xs={role !== "Customer" && role !== "CustomerUser" ? 12 : 6}
              >
                <Input
                  select
                  value={bookingState.noOfPeople}
                  fullWidth
                  size="small"
                  variant="outlined"
                  className={classes.textfield_lower}
                  onChange={(e) => updateField("noOfPeople", e.target.value)}
                  label="Passengers"
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((item, index) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Input>
              </Grid> */}
            </Grid>
          </Grid>
          {/* cars */}
          <Grid className={classes.MultiSelect}>
            <CarExclusiveSelect
              value={bookingState.carType}
              noOfPeople={bookingState.noOfPeople}
              onClick={(value) => updateField("carType", value)}
            />
          </Grid>
          <LocationField
            location={bookingState.pickup}
            placeholder={"Select Pickup Location"}
            onChange={(value) => {
              if (value) {
                geocodeByAddress(value.label)
                  .then((results) => getLatLng(results[0]))
                  .then(({ lat, lng }) =>
                    updateField("pickup", {
                      name: value,
                      coordinates: [lat, lng],
                    })
                  );
              }
            }}
          />
          {errorTrigger && bookingState.pickup?.coordinates?.length === 0 && (
            <p
              className="error-text-danger error-text-style"
              style={{ margin: "0" }}
            >
              Pickup is required.
            </p>
          )}
          <LocationField
            location={bookingState.destination}
            placeholder={"Select Destination Location"}
            onChange={(value) => {
              if (value) {
                geocodeByAddress(value.label)
                  .then((results) => getLatLng(results[0]))
                  .then(({ lat, lng }) =>
                    updateField("destination", {
                      name: value,
                      coordinates: [lat, lng],
                    })
                  );
              }
            }}
          />

          {errorTrigger &&
            bookingState.destination?.coordinates?.length === 0 && (
              <p
                className="error-text-danger error-text-style"
                style={{ margin: "0" }}
              >
                Destination is required.
              </p>
            )}
          <Grid
            container
            direction="row"
            // justifyContent="flex-end"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 36 }}
          >
            {/* <Button
              // disabled
              style={{
                borderRadius: 6,
                backgroundColor: "primary",
                color: "#FFFFFF",
                height: 40,
                marginLeft: 10,
              }}
              variant="contained"
              onClick={onClose}
            >
              Cancel
            </Button> */}

            {/* <Button
              className={classes.button}
              disabled={
                !bookingState.guestPhoneNumber ||
                !bookingState.dateTime ||
                !bookingState.destination ||
                !bookingState.noOfPeople ||
                !bookingState.pickup ||
                // !bookingState.clientId ||
                !bookingState.guestName
              }
              variant="contained"
              onClick={() => {
                if (role == "Customer" || role == "CustomerUser") {
                  createCustomerBooking();
                }
                if (role == "Organization" || role == "OrgUser") {
                  createBooking();
                }
                getTotalAndAssignedRides()
                onClose();
              }}
            >
              Create
            </Button> */}
            <Box>
              <Typography
                style={{ color: "#29AEE6", cursor: "pointer" }}
                onClick={() => {
                  resetAll();
                  setNumberOfGuest(1);
                  setRefresh(!refresh);
                  setGuestName("");
                  updateField("guestName", "");
                  setInputGuestName("");
                }}
              >
                Reset
              </Typography>
            </Box>
            <Box display={"flex"}>
              <Box style={{ marginRight: 15 }}>
                <MOButton
                  title=" Cancel"
                  icon={false}
                  onClick={onClose}
                  type="secondary"
                />
              </Box>
              <Box>
                <MOButton
                  title="   Create"
                  icon={false}
                  onClick={async() => {
                    if (
                      
                      bookingState.guestPhoneNumber &&
                      bookingState.dateTime &&
                      bookingState.destination &&
                      bookingState.noOfPeople &&
                      bookingState.pickup &&
                      bookingState.guestName &&
                      !validateText(bookingState.guestName) &&
                      !validateFlightNumber(bookingState.flightNumber)
                    ) {
                      let bookingCreateStatus=false;
                      if (role == "Customer" || role == "CustomerUser") {
                        createCustomerBooking();
                        bookingCreateStatus=true;
                      }
                      if (role == "Organization" || role == "OrgUser") {
                        if(bookingState?.clientId){
                          bookingCreateStatus=await createBooking();
                          setSearchText(bookingCreateStatus)
                          
                        }else{
                          setErrorTrigger(true);
                        }
                      }
                      if(role != "Organization" || role != "OrgUser"){

                        getTotalAndAssignedRides();
                      }

                      if(bookingCreateStatus){
                        onClose();
                      }

                     
                    } else {
                      setErrorTrigger(true);
                    }

                    // if (role == "Customer" || role == "CustomerUser") {
                    //   createCustomerBooking();
                    // }
                    // if (role == "Organization" || role == "OrgUser") {
                    //   createBooking();
                    // }
                    // getTotalAndAssignedRides();
                    // onClose();
                  }}
                  // disabled={
                  //   !bookingState.guestPhoneNumber ||
                  //   !bookingState.dateTime ||
                  //   !bookingState.destination ||
                  //   !bookingState.noOfPeople ||
                  //   !bookingState.pickup ||
                  //   // !bookingState.clientId ||
                  //   !bookingState.guestName
                  // }
                />
              </Box>
            </Box>
          </Grid>
        </div>
      </Drawer>
      {createBookingError.status && (
        <ErrorSnack reset={() => reset()} errorMsg={error.message} />
      )}
      {/* {createBookingSuccess.status && (
        <ErrorSnack
          reset={() => reset()}
          success
          errorMsg={"Booking Created"}
        />
      )} */}
      {createPassengerError.status && (
        <ErrorSnack
          reset={() => reset()}
          errorMsg={"Phone number has already existed"}
        />
      )}
      {guestNameEmpty && (
        <ErrorSnack
          reset={() => setGuestNameEmpty(false)}
          errorMsg={"You must insert Guest Name and Phone Number."}
        />
      )}
      {createError != "" && (
        <ErrorSnack reset={() => setCreateError("")} errorMsg={createError} />
      )}
      {passengerCreate && (
        <ErrorSnack
          reset={() => setPassengerCreate(false)}
          success
          errorMsg={"Passenger Created"}
        />
      )}
    </>
  );
}
const mapState = (state) => ({
  error: state.customers.error,
  success: state.customers.response,
  dispatchers: state.customers.response.data,
  passengers: state.passengers.response.data,
  createBookingError: state.customerbooking.error,
  createBookingSuccess: state.customerbooking.response,
  createPassengerSuccess: state.passengers.response,
  createPassengerError: state.passengers.error,
  loading: state.customers.loading,
  bookingState: state.bookings.state,
});

const mapDispatch = {
  updateField: Bookings.field.update,
  getCustomers: ThunksCustomers.Customers,
  getDispatchers: ThunksCustomers.getCustomerDispatchers,
  createBooking: ThunksBookings.CreateBooking,
  createCustomerBooking: ThunksBookings.CreateCustomerBooking,
  reset: Local.CreateBooking.api.reset,
  resetAll: Local.CreateBooking.resetAll,
  getPassengers: ThunksPassengers.Passengers,
  createPassenger: ThunksPassengers.createPassenger,
  getTotalAndAssignedRides: ThunksBooking.getTotalAndAssignedRides,
};

export default connect(mapState, mapDispatch)(SimpleDialog);
