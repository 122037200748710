import getSymbolFromCurrency from "currency-symbol-map";

export const formatCurrency = (currency = "EUR", amount = "0.00") => {
    try {
        const symbol = getSymbolFromCurrency(currency) || "";
        const safeAmount = amount ?? "0.00";
        const formattedAmount = parseFloat(safeAmount).toFixed(2);
        return `${symbol}${formattedAmount}`;
    } catch (error) {
        console.error("Error fetching countries", error);
        return "NULL";
      }

};

