import React from "react";
import PriceIcon from "../../../../assets/icons/dashboard_price_icon.svg";
import CompletedTransfer from "../../../../assets/icons/dashbaord_transfer_icon.svg";
import PendingTransfer from "../../../../assets/icons/dashboard_setting_icon.svg";
import CanceledTransfer from "../../../../assets/icons/dashbaord_cancel_icon.svg";
import { ExpandMore, InfoOutlined } from "@material-ui/icons";
import Select from "react-select";
import { useNavigate } from "react-router";
import SearchingInput from "../../../../components/commons/SearchingInput";
import EditIcon from "../../../../assets/icons/lucide_edit.svg";
import BarChart from "../../../../components/commons/BarChart";
import LineChartV2 from "../../../../components/commons/LineChartV2";
import PieChart from "../../../../components/commons/PieChart";
import { formatCurrency } from "../../../../helpers/getSymbolFromCurrency";

//
const cardData = [
  {
    title: "Total Requests",
    menu: true,
    value: 59383,
    icon: PriceIcon,
  },
  {
    title: "Completed Transfers",
    menu: false,
    value: 7463,
    icon: CompletedTransfer,
  },
  {
    title: "Pending Transfers",
    menu: false,
    value: 56,
    icon: PendingTransfer,
  },
  {
    title: "Cancelled Transfers",
    menu: false,
    value: 22,
    icon: CanceledTransfer,
  },
];
const data = [
  {
    search: {
      date: "02.01.2024",
      time: "Morning",
    },
    booking: {
      date: "02.01.2024",
      time: "10:20",
    },
    pickup: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    drop: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    service: "Standard",
    frequency: 40,
    conversion: 3,
    rate: 42,
  },
  {
    search: {
      date: "02.01.2024",
      time: "Afternoon",
    },
    booking: {
      date: "02.01.2024",
      time: "13:20",
    },
    pickup: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    drop: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    service: "Standard",
    frequency: 40,
    conversion: 3,
    rate: 42,
  },
  {
    search: {
      date: "02.01.2024",
      time: "Evening",
    },
    booking: {
      date: "02.01.2024",
      time: "15:20",
    },
    pickup: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    drop: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    service: "Standard",
    frequency: 40,
    conversion: 0,
    rate: 42,
  },
  {
    search: {
      date: "02.01.2024",
      time: "Night",
    },
    booking: {
      date: "02.01.2024",
      time: "18:20",
    },
    pickup: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    drop: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    service: "Standard",
    frequency: 40,
    conversion: 2,
    rate: 42,
  },
  {
    search: {
      date: "02.01.2024",
      time: "Morning",
    },
    booking: {
      date: "02.01.2024",
      time: "10:20",
    },
    pickup: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    drop: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    service: "Standard",
    frequency: 40,
    conversion: 3,
    rate: 42,
  },
  {
    search: {
      date: "02.01.2024",
      time: "Afternoon",
    },
    booking: {
      date: "02.01.2024",
      time: "13:20",
    },
    pickup: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    drop: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    service: "Standard",
    frequency: 40,
    conversion: 3,
    rate: 42,
  },
  {
    search: {
      date: "02.01.2024",
      time: "Evening",
    },
    booking: {
      date: "02.01.2024",
      time: "15:20",
    },
    pickup: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    drop: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    service: "Standard",
    frequency: 40,
    conversion: 0,
    rate: 42,
  },
  {
    search: {
      date: "02.01.2024",
      time: "Night",
    },
    booking: {
      date: "02.01.2024",
      time: "18:20",
    },
    pickup: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    drop: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    service: "Standard",
    frequency: 40,
    conversion: 2,
    rate: 42,
  },
];
const dateRanges = [
  { value: "01-01-2024-07-01-2024", label: "01-01-2024 to 07-01-2024" },
  { value: "08-01-2024-14-01-2024", label: "08-01-2024 to 14-01-2024" },
  { value: "15-01-2024-21-01-2024", label: "15-01-2024 to 21-01-2024" },
  { value: "22-01-2024-28-01-2024", label: "22-01-2024 to 28-01-2024" },
  { value: "29-01-2024-04-02-2024", label: "29-01-2024 to 04-02-2024" },
  { value: "05-02-2024-11-02-2024", label: "05-02-2024 to 11-02-2024" },
  { value: "12-02-2024-18-02-2024", label: "12-02-2024 to 18-02-2024" },
  { value: "19-02-2024-25-02-2024", label: "19-02-2024 to 25-02-2024" },
];

// Define routes options
const routes = [
  { value: "PAK", label: "PAK" },
  { value: "UK", label: "UK" },
  { value: "AMS", label: "AMS" },
  { value: "NYC", label: "NYC" },
  { value: "LON", label: "LON" },
  { value: "DXB", label: "DXB" },
  { value: "BKK", label: "BKK" },
  { value: "SIN", label: "SIN" },
];
const Dashboard = () => {
  const navigate = useNavigate();
  const weekFormatOptionLabel = (name, { context }) => (
    <>
      {context === "value" && (
        <p className="m-0 ">
          <span className="font-600 ">This week </span>
          <span className="font-12 font-400"> {`(From ${name.label})`}</span>
        </p>
      )}

      {context === "menu" && (
        <>
          <p className="font-14 mb-0">{name.label}</p>
        </>
      )}
    </>
  );
  const routeFormatOptionLabel = (name, { context }) => (
    <>
      {context === "value" && (
        <p className="m-0 ">
          <span className="font-400 font-400 ">Most Searched Routes: </span>
          <span className="font-14 font-600"> {name.label}</span>
        </p>
      )}

      {context === "menu" && (
        <>
          <p className="font-14 mb-0">{name.label}</p>
        </>
      )}
    </>
  );
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "1px solid #ced4da" : "1px solid #ced4da",
      boxShadow: state.isFocused ? "0 0 0 1px #ced4da" : provided.boxShadow,
      "&:hover": {
        border: "1px solid #ced4da",
      },
    }),
    menu: (provided) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "1px 6px",
      backgroundColor: state.isSelected ? "#29AEE6" : "#fff",
      color: state.isSelected ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: state.isSelected ? "#0056b3" : "#e9ecef",
        color: state.isSelected ? "#fff" : "#000",
      },
    }),
    // singleValue: (provided) => ({
    //   ...provided,
    //   fontSize: "16px",
    //   color: "#495057",
    // }),
  };
  //
  const chartData = {
    labels: [
      "Polygon Region 1",
      "Polygon Region 2",
      "Polygon Region 3",
      "Polygon Region 4",
      "Polygon Region 5",
      "Polygon Region 6",
      "Polygon Region 7",
      "Polygon Region 8",
    ],
    datasets: [
      {
        label: false,
        data: [100, 300, 400, 500, 600, 200, 300, 400],
        backgroundColor: "#29AEE6",
        borderColor: "#29AEE6",
        borderWidth: 1,
        barThickness: 44,
      },
    ],
  };
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        titleFont: {
          size: 9,
          weight: "normal",
          color: "#000000",
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 9,
            weight: "normal",
            color: "#000000",
          },
        },
      },
      y: {
        grid: {
          display: true, // Show horizontal grid lines
          borderDash: [5, 5], // Add dashed lines for the grid
        },
        ticks: {
          font: {
            size: 9,
            weight: "normal",
            color: "#000000",
          },
        },
      },
    },
  };
  //
  const lineChartData = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: false,
        data: Array.from(
          { length: 12 },
          () => Math.floor(Math.random() * (60000 - 10000 + 1)) + 10000
        ),
        backgroundColor: "rgba(69, 178, 107, 0.2)",
        borderColor: "#45B26B",
        borderWidth: 2,
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ],
  };

  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        titleFont: {
          size: 9,
          weight: "normal",
          color: "#000000",
        },
        bodyFont: {
          size: 9,
          weight: "normal",
          color: "#000000",
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide vertical grid lines
        },
        ticks: {
          font: {
            size: 9,
            weight: "normal",
            color: "#000000",
          },
        },
      },
      y: {
        grid: {
          display: true, // Show horizontal grid lines
          borderDash: [5, 5], // Add dashed lines for the grid
        },
        ticks: {
          font: {
            size: 9,
            weight: "normal",
            color: "#000000",
          },
        },
      },
    },
  };
  //
  const piaChartData = {
    labels: ["Completed Transfers", "Pending Transfers", "Cancelled Transfers"],
    datasets: [
      {
        data: [60, 30, 10], // Example data
        backgroundColor: [
          "#0059C8", // Completed Transfers color
          "#E2A03F", // Pending Transfers color
          "#FF0000", // Cancelled Transfers color
        ],
        borderWidth: 1,
      },
    ],
  };

  const paiChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "right", // Legend position
        labels: {
          font: {
            size: 9,
            weight: "normal",
            color: "#000000",
          },
        },
      },
      tooltip: {
        titleFont: {
          size: 9,
          weight: "normal",
          color: "#000000",
        },
        bodyFont: {
          size: 9,
          weight: "normal",
          color: "#000000",
        },
      },
    },
  };
  return (
    <div>
      <div>
        {/* upper section */}
        <div className="mt-18 flex gap-16">
          {cardData?.map((item) => {
            return (
              <>
                <div className="analysis-card-md">
                  <div className="flex justify-content-between">
                    <p className="font-12 font-400">{item?.title}</p>
                    {item?.menu && (
                      <div className="badge-menu flex align-item-center">
                        <p className="">daily </p>
                        <ExpandMore
                          style={{
                            color: "#000000",
                            // width: "7px",
                            // height: "4px",
                            fontSize: "13px",
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="flex justify-content-between align-item-center mt-18">
                    <h3 className="font-20 font-700">{item.value}</h3>
                    <div>
                      <img src={item.icon} />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        {/* chart area */}
        <div>
          {/*  */}
          <div className="flex gap-16">
            <div
              className="bg-white border-radius-6 analysis-dashboard-no-poly"
              style={{
                width: "66%",
              }}
            >
              <div className="mb-27 ">
                <p className="font-10 font-600">Total Requests</p>
              </div>
              <LineChartV2 data={lineChartData} options={lineChartOptions} />
            </div>
            <div
              style={{
                width: "34%",
              }}
              className="bg-white border-radius-6 analysis-dashboard-no-pia "
            >
              <div className="mb-27 ">
                <p className="font-10 font-600">Cancelled Transfers</p>
              </div>
              <PieChart data={piaChartData} options={paiChartOptions} />
            </div>
          </div>
          {/*  */}
          <div className="bg-white border-radius-6 analysis-dashboard-no-poly">
            <div className="mb-27 ">
              <p className="font-10 font-600">Number of Requests Per Polygon</p>
            </div>
            <BarChart data={chartData} options={chartOptions} />
          </div>
        </div>
        {/* tables area */}
        <div className="">
          {/* Searching Area */}
          <div className="mt-16">
            <div>
              <p className="m-0 font-14 font-400 mb-8">Time Frame</p>
              <div className="flex justify-content-between width-full">
                <div className="flex gap-10">
                  <div className="w-341">
                    <Select
                      options={dateRanges}
                      placeholder="Select date range"
                      formatOptionLabel={weekFormatOptionLabel}
                      styles={customStyles}
                      isSearchable={false}
                    />
                  </div>
                  <div className="w-256">
                    <Select
                      options={routes}
                      placeholder="Select the route"
                      formatOptionLabel={routeFormatOptionLabel}
                      styles={customStyles}
                      isSearchable={false}
                    />
                  </div>
                </div>
                <div>
                  <SearchingInput />
                </div>
              </div>
            </div>
          </div>
          {/* table */}
          <div className=" mt-30">
            <table className="custom-table-v3">
              <thead>
                <tr>
                  <th className="">Frequency</th>
                  <th className="">Conversion </th>
                  <th className="">Search</th>
                  <th className="">Booking</th>
                  <th className="">Pickup Address</th>
                  <th className="">Drop-off Address</th>
                  <th className="">Service Level</th>
                  <th className="">Set Rate</th>
                  <th className=""></th>
                </tr>
              </thead>
              <tbody>
                {data?.map((row, index) => (
                  <tr key={index}>
                    <td>{row.frequency}</td>
                    <td>
                      {row.conversion > 0 ? (
                        <span style={{ color: "#428702" }}>
                          {row.conversion}
                        </span>
                      ) : (
                        <span style={{ color: "#FC0808" }}>
                          {row.conversion}
                        </span>
                      )}
                    </td>
                    <td>
                      <p className="m-0"> {row.search.date}</p>
                      <p className="m-0"> {row.search.time}</p>
                    </td>
                    <td>
                      <p className="m-0">{row.booking.date}</p>
                      <p className="m-0">{row.booking.time}</p>
                    </td>
                    <td style={{ maxWidth: "135px" }}>{row.pickup}</td>
                    <td style={{ maxWidth: "135px" }}>{row.drop}</td>
                    <td>{row.service}</td>
                    {/* <td>€{row?.rate}</td> */}
                    <td>{formatCurrency(row?.currency, row?.rate)}</td>
                    <td>
                      <img
                        src={EditIcon}
                        alt="Edit"
                        style={{
                          width: "21px",
                          height: "21px",
                          cursor: "pointer",
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
